import React, { useRef } from "react";
import { useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import { latlngWithDirection } from "../../utils";

const monday = mondaySdk();

export function AirQualityItem(){
  const iframeRef = useRef();
    useEffect(() => {

      function updateMap(context, settings){
        const locationColumnId = Object.keys(settings.locationColumnId)[0];
        if(locationColumnId){
          const { boardId, itemId } = context;
          monday.api(`query {
            items (ids: [${itemId}]) {
              column_values {
                id
                value
              }
            }
          }`).then(res => {
            const column = res.data.items[0].column_values.find(column => {
              return column.id === locationColumnId;
            });
            const location = JSON.parse(column.value);
            const url = `https://embed.windy.com/embed2.html?lat=${location.lat}&lon=${location.lng}&detailLat=${location.lat}&detailLon=${location.lng}&width=650&height=450&zoom=6&level=surface&overlay=temp&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=${encodeURIComponent(settings.windspeedUnit)}&metricTemp=${encodeURIComponent(settings.temperatureUnit)}&radarRange=-1`;
            iframeRef.current.setAttribute("src", url);
          })
        }
      }

      monday.listen("settings", res => {
        const settings = res.data;
        monday.get("context").then(res => res.data)
        .then(context => {
          updateMap(context, settings);
        });
      });
      
      Promise.all([
        monday.get("context").then(res => res.data),
        monday.get("settings").then(res => res.data)
      ])
      .then(([context, settings]) => {
        updateMap(context, settings);
      });

    }, []);
    return <div style={{ width: "100%", height: "100%" }}>
        <iframe ref={iframeRef} frameBorder="0" scrolling="NO" allowtransparency="true" sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox" style={{ width: "100%", height: "calc(100% - 5px)" }}></iframe>
    </div>;
}