import React from "react";
import { useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import "./weatherboard.css";
import { useState } from "react";
import { useRef } from "react";

const monday = mondaySdk();

const FILTERS = {
  weatherColumnId: "Weather",
  temperatureCColumnId: "Temperature (C)",
  temperatureFColumnId: "Temperature (F)",
  windMPHColumnId: "Wind Speed (mph)",
  windKPHColumnId: "Wind Speed (kph)",
  winddirectionColumnId: "Wind Direction",
  winddegreeColumnId: "Wind Degree",
  pressurembColumnId: "Pressure (mb)",
  pressureinColumnId: "Pressure (in)",
  precipitationmmColumnId: "Precipitation (mm)",
  precipitationinColumnId: "Precipitation (in)",
  humidityColumnId: "Humidity",
  cloudColumnId: "Cloud",
  feelslikecColumnId: "Feels Like (C)",
  feelslikefColumnId: "Feels Like (F)",
  visibilitykmColumnId: "Visibility (KM)",
  visibilitymilesColumnId: "Visibility (Miles)",
  uvColumnId: "UV",
  windgustmphColumnId: "Wind Gust (mph)",
  windgustkphColumnId: "Wind Gust (kph)",
};

export function WeatherBoard() {
  const [currentFilter, setCurrentFilter] = useState(null);
  const currentFilterRef = useRef(null);
  const markers = useRef([]);
  const [filters, setFilters] = useState([]);
  const filtersRef = useRef([]);
  const mapRef = useRef(null);
  const ready = useRef(false);

  function drawMarker(context, settings, map, currentFilter) {
    monday
      .api(
        `query {
      boards (ids: [${context.boardId}]) {
        name
        items {
          id
          column_values {
            id
            value
            text
          }
        }
      }
    }`
      )
      .then((res) => {
        const locationColumnId = Object.keys(settings.locationColumnId)[0];
        const items = res.data.boards[0].items;
        markers.current.map((marker) => {
          marker.setMap(null);
        });
        markers.current.length = 0;
        items.forEach((item) => {
          const column = item.column_values.find(
            (column) => column.id === locationColumnId
          );
          if (column.value) {
            const location = JSON.parse(column.value);
            let value = null;
            if(currentFilter){
              const columnId = Object.keys(settings[currentFilter])[0];
              value = item.column_values.find(
                (column) => column.id === columnId
              ).text
            }
            
            const marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(
                location.lat,
                location.lng
              ),
              map,
              label: currentFilter
                ? {
                  text: value,
                  fontWeight: "600",
                }
                : null,
              icon: currentFilter
                ? {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 20,
                    fillColor: "#F00",
                    fillOpacity: 0.4,
                    strokeWeight: 0.4,
                  }
                : null,
            });
            markers.current.push(marker);
            marker.itemId = item.id;

            marker.addListener("click", function () {
              const item = items.find((item) => item.id === this.itemId);
              console.log("ITEM", item);
              const infowindow = new window.google.maps.InfoWindow({
                content: `<div>
                ${location.address && `<span style="display:block"><b>${location.address}</b></span>`}
              ${filtersRef.current
                .map((filter) => {
                  const columnId = Object.keys(settings[filter.id])[0];
                  const column_v = item.column_values.find(
                    (column) => column.id === columnId
                  );
                  if (column_v.value) {
                    return `<span style="display:block">
                    ${filter.label}: <b>${
                      item.column_values.find(
                        (column) => column.id === columnId
                      ).text
                    }</b>
                  </span>`;
                  } else {
                    return "";
                  }
                })
                .join("")}
            </div>`,
              });
              infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
              });
            });
          }
        });
      });
  }

  useEffect(() => {
    if(ready.current) return;
    ready.current = true;
    const uluru = { lat: -25.344, lng: 131.031 };
    // The map, centered at Uluru
    const map = mapRef.current = new window.google.maps.Map(
      document.getElementById("weatherboard"),
      {
        zoom: 3,
        center: uluru,
        fullscreen: false,
      }
    );

    monday.listen("settings", (res) => {
      console.log("LISTE NSETTINGS CALKLED");
      const settings = res.data;
      filtersRef.current = Object.keys(FILTERS)
        .filter((filter) => {
          return !!res.data[filter];
        })
        .map((filter) => {
          return { id: filter, label: FILTERS[filter] };
        });
      setFilters(filtersRef.current);

      monday.get("context").then((res) => {
        console.log("CONTEXT GET MARK");
        drawMarker(res.data, settings, mapRef.current, currentFilterRef.current);
      });
    });

  }, []);

  useEffect(() => {
    currentFilterRef.current = currentFilter;
    monday.get("settings").then((res) => {});
  }, [currentFilter]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div id="weatherboard" style={{ width: "100%", height: "100%" }} />
      <div className="weatheroptions">
        {filters.length > 0 && (
          <button className="button">
            {!currentFilter ? "None" : FILTERS[currentFilter]}
          </button>
        )}
        <div className="weatherswitches">
          <button
            className="button"
            onClick={() => {
              setCurrentFilter(null);
            }}
          >
            None
          </button>
          {filters.map((filter) => {
            return (
              <button
                className="button"
                onClick={() => {
                  setCurrentFilter(filter.id);
                }}
              >
                {FILTERS[filter.id]}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
