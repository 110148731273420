import React from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import { Routes, Route, Link } from "react-router-dom";
import "monday-ui-react-core/dist/main.css"
//Explore more Monday React Components here: https://style.monday.com/
import AttentionBox from "monday-ui-react-core/dist/AttentionBox.js"
import { WeatherBoard } from "./pages/weatherboard";
import { AirQualityItem } from "./pages/airqualityitem";

const monday = mondaySdk();

class App extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      settings: {},
      name: "",
    };
  }

  componentDidMount() {
    
  }

  render() {
    return <div className="App">
      <Routes>
        <Route path="/mapboard" element={<WeatherBoard />} />
        <Route path="/weatheritem" element={<AirQualityItem />} />
      </Routes>
    </div>;
  }
}

export default App;
